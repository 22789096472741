import moment from 'moment/moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import { AppContext } from 'context';
import { getStandards } from 'helpers/settings';
import Avatar from 'components/Avatar';
import App from 'modules/App';
import Account from 'modules/Account';
import CloudDrive from 'modules/CloudDrive';
import Patient from 'modules/Patient';
import messages from '../../messages';
import Results from '../Results';
import styles from '../Results/Results.pcss';


class FamilyMemberResults extends React.PureComponent {

  static contextType = AppContext;


  static getDerivedStateFromProps(props, state) {
    const { countrySettings, phiSet, familyLinkId } = props;
    const phiGlucoseLevelTargets = get(phiSet, 'glucoseLevelTargets', {});

    if ((!state.standards && countrySettings)
      || !isEqual(phiGlucoseLevelTargets, state.glucoseLevelTargets)
      || familyLinkId !== state.familyLinkId
    ) {
      const standards = getStandards(phiSet, countrySettings);

      const glucoseLevelTargets = { ...standards };
      delete glucoseLevelTargets.maxValue;
      delete glucoseLevelTargets.minValue;

      return { standards, glucoseLevelTargets, familyLinkId };
    }

    return null;
  }


  static propTypes = {
    // Explicit props
    familyLinkId            : PropTypes.number,
    // Implicit props
    familyLinks             : PropTypes.arrayOf(Patient.shapes.familyLinkRequest),
    activeFamilyLink        : Patient.shapes.activeFamilyLink,
    phiSet                  : PropTypes.object, // @TODO: shape
    phiSetDocumentId        : PropTypes.string,
    batchesIndex            : PropTypes.arrayOf(PropTypes.string),
    cgmBatchesIndex         : PropTypes.arrayOf(PropTypes.string),
    measurementsBatchesIndex: PropTypes.arrayOf(PropTypes.string),
    readings                : PropTypes.array, // @TODO: shape
    relatedData             : PropTypes.array, // @TODO: shape
    measurements            : PropTypes.array, // @TODO: shape
    patientProfile          : PropTypes.shape({ patientProfileId: PropTypes.number.isRequired }),
    cgProfile               : PropTypes.shape({ cgProfileId: PropTypes.number.isRequired }),
    route                   : App.shapes.route,
    // Implicit actions
    onActivate              : PropTypes.func,
    onDeactivate            : PropTypes.func,
    onFetchPhiSet           : PropTypes.func,
    onFetchReadings         : PropTypes.func,
    onUpdatePhiSet          : PropTypes.func,
  };


  constructor(props, context) {
    super(props);
    this.state = {
      standards          : null,
      glucoseLevelTargets: {},
      familyLinkId       : null,
    };

    if (!process.env.BROWSER) {
      const { familyLinkId, familyLinks } = props;
      if (familyLinks) {
        const familyLink = find(familyLinks, { familyLinkId });
        const hasAccess = this.getHasAccess(familyLink);
        if (!hasAccess) {
          context.redirect(context.getUrl('my-results'));
        }
      }
    }
  }


  componentDidMount() {
    this.onActivate();
  }


  componentDidUpdate(prevProps) {
    const { familyLinkId, activeFamilyLink, familyLinks } = this.props;
    const activeFamilyLinkId = get(activeFamilyLink, 'familyLinkId', null);
    if (activeFamilyLinkId !== familyLinkId) {
      this.onActivate();
      return;
    }

    if (prevProps.familyLinks !== familyLinks && familyLinks) {
      const familyLink = find(familyLinks, { familyLinkId });
      const hasAccess = this.getHasAccess(familyLink);
      if (!hasAccess) {
        this.context.redirect(this.context.getUrl('my-results'));
        return;
      }
    }

    const phiSetReferenceKey = get(activeFamilyLink, 'phiSetReferenceKey');
    if (phiSetReferenceKey && phiSetReferenceKey !== get(prevProps.activeFamilyLink, 'phiSetReferenceKey')) {
      this.props.onFetchPhiSet(activeFamilyLink);
    }
  }


  componentWillUnmount() {
    const { name } = this.props.route;
    if (name !== 'family-member-results') {
      this.props.onDeactivate();
    }
  }


  onActivate() {
    if (!process.env.BROWSER) {
      return;
    }
    const { familyLinkId, familyLinks } = this.props;
    if (!familyLinks) {
      return;
    }
    const familyLink = find(familyLinks, { familyLinkId });
    const hasAccess = this.getHasAccess(familyLink);
    if (!hasAccess) {
      this.context.redirect(this.context.getUrl('my-results'));
      return;
    }
    this.props.onActivate(familyLink);
  }


  onRangeChange(startDate, endDate) {
    const {
      activeFamilyLink,
      phiSet, phiSetDocumentId, batchesIndex,
      cgmBatchesIndex, measurementsBatchesIndex,
    } = this.props;
    if (!activeFamilyLink) {
      return;
    }
    const { phiSetReferenceKey, accessToken, storageProvider } = activeFamilyLink;
    this.props.onFetchReadings({
      phiSet,
      phiSetReferenceKey,
      phiSetDocumentId,
      batchesIndex,
      cgmBatchesIndex,
      measurementsBatchesIndex,
      accessToken,
      storageProvider,
      startDate,
      endDate,
      successAction: Patient.actions.setReadings,
    });
  }


  onUpdatePhiSet(entries) {
    const { phiSet, phiSetDocumentId, activeFamilyLink } = this.props;
    this.props.onUpdatePhiSet(entries, phiSet, phiSetDocumentId, activeFamilyLink, Patient.actions.setPhiSet);
  }


  get ageValue() {
    const dateOfBirth = get(this.props.activeFamilyLink, 'dateOfBirth');
    if (!dateOfBirth) return null;
    const patientYears = moment().diff(dateOfBirth, 'years');
    return (
      <>
        { `${patientYears} ` }
        <FormattedMessage {...messages.labels.yearsOld} />
      </>
    );
  }


  get diabetesTypeValue() {
    const { phiSet } = this.props;
    const diabetesType = get(phiSet, 'diabetesType');
    if (!diabetesType) return null;
    return <FormattedMessage {...App.messages.diabetesTypes[diabetesType]} />;
  }


  get treatmentTypeValue() {
    const { phiSet } = this.props;
    const treatmentType = get(phiSet, 'treatmentType');
    if (!treatmentType) return null;
    return <FormattedMessage {...App.messages.treatmentTypes[treatmentType]} />;
  }


  getHasAccess(familyLink) {
    return familyLink && familyLink.encryptedExchangeToken && familyLink.status === 'Approved';
  }


  renderPatientInformation(label, value, withoutMargin) {
    return (
      <>
        <p className={styles.patientAdditionalInfoLabel}><FormattedMessage {...label} />:</p>
        <p className={cn(styles.patientAdditionalInfoValue, {
          [styles['patientAdditionalInfoValue--noMargin']]: withoutMargin,
        })}
        >
          { value || '-' }
        </p>
      </>
    );
  }


  renderAdditionalPatientInformation() {
    return (
      <div className={cn(styles.patientName__wrapper, styles.additionalPatientInfo)}>
        { this.renderPatientInformation(messages.labels.age, this.ageValue) }
        { this.renderPatientInformation(messages.labels.diabetesType, this.diabetesTypeValue) }
        { this.renderPatientInformation(messages.labels.treatmentType, this.treatmentTypeValue, true) }
      </div>
    );
  }


  renderPatientHeader() {
    const { activeFamilyLink } = this.props;
    const { avatar, firstName, lastName } = activeFamilyLink || {};
    return (
      <div className="col">
        <div className="row align-items-center">
          <div className="col-auto">
            {
              activeFamilyLink
                ? (
                  <Avatar
                    avatarImg={avatar}
                    name={[firstName, lastName]}
                    className={styles.patient__avatar}
                    imgClassName={styles.patient__avatar__img}
                    initialsClassName={styles.patient__avatar__initials}
                  />
                )
                : <div className={styles.patient__avatar} />
            }
          </div>
          <div className="col">
            <p className={cn('text--h1', styles.patient__name)}>
              { firstName } { lastName }
            </p>
            <div className="row">
              <div className="d-flex pt-3 justify-content-center align-items-center">
                { this.renderAdditionalPatientInformation() }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }


  render() {
    return (
      <Results
        phiSet={this.props.phiSet}
        phiSetDocumentId={this.props.phiSetDocumentId}
        patient={this.props.activeFamilyLink}
        readings={this.props.readings}
        relatedData={this.props.relatedData}
        measurements={this.props.measurements}
        standards={this.state.standards}
        isPatientMode
        isReadOnly
        renderPatientHeader={() => this.renderPatientHeader()}
        onRangeChange={(startDate, endDate) => this.onRangeChange(startDate, endDate)}
        onMeasurementsSuccess={Patient.actions.setPhiSet}
        onUpdatePhiSet={(entries) => this.onUpdatePhiSet(entries)}
      />
    );
  }

}


const mapStateToProps = (state) => ({
  patientProfile          : Account.selectors.patientProfile(state),
  cgProfile               : Account.selectors.cgProfile(state),
  countrySettings         : Account.selectors.countrySettings(state),
  familyLinks             : Patient.selectors.familyLinkRequests(state),
  activeFamilyLink        : Patient.selectors.activeFamilyLink(state),
  phiSet                  : Patient.selectors.phiSet(state),
  phiSetDocumentId        : Patient.selectors.phiSetDocumentId(state),
  batchesIndex            : Patient.selectors.batchesIndex(state),
  cgmBatchesIndex         : Patient.selectors.cgmBatchesIndex(state),
  measurementsBatchesIndex: Patient.selectors.measurementsBatchesIndex(state),
  readings                : Patient.selectors.readings(state),
  measurements            : Patient.selectors.measurements(state),
  relatedData             : Patient.selectors.relatedData(state),
  route                   : App.selectors.route(state),
});


const mapDispatchToProps = (dispatch) => ({
  onActivate   : (familyLink) => dispatch(Patient.actions.activateFamilyMember(familyLink)),
  onDeactivate : () => dispatch(Patient.actions.deactivateFamilyMember()),
  onFetchPhiSet: (activeFamilyLink) => dispatch(
    CloudDrive.actions.fetchPhiSet(activeFamilyLink, Patient.actions.setPhiSet),
  ),
  onFetchReadings: (constraints) => dispatch(CloudDrive.actions.fetchReadings(constraints)),
  onUpdatePhiSet : (entries, phiSet, phiSetDocumentId, activeFamilyLink, successAction) => dispatch(
    CloudDrive.actions.updatePhiSet(entries, phiSet, phiSetDocumentId, activeFamilyLink, successAction),
  ),
});


const ConnectedFamilyMemberResults = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FamilyMemberResults);


export default ConnectedFamilyMemberResults;
