import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import pick from 'lodash/pick';
import filter from 'lodash/filter';
import valuesIn from 'lodash/valuesIn';
import times from 'lodash/times';
import isNaN from 'lodash/isNaN';
import Validator from 'libs/Validator';
import FormContainerAbstract from 'components/FormContainerAbstract';
import Form from 'components/Form';
import Button from 'components/Form/Button';
import App from 'modules/App';
import * as actions from '../../../actions';
import * as constants from '../../../constants';
import * as selectors from '../../../selectors';
import messages from '../../../messages';
import styles from '../DownloadDataModal.pcss';
import validatorRules from './validatorRules.json';


class BluetoothPin extends FormContainerAbstract {


  static getDerivedStateFromProps(props, state) {
    const { isInProgress, hasErrors } = props;

    if (isInProgress !== state.isInProgress && isInProgress) {
      return { isInProgress: true };
    }
    if (hasErrors && state.isInProgress) {
      return { isInProgress: false };
    }
    return null;
  }


  static propTypes = {
    ...FormContainerAbstract.propTypes,
    // Explicit actions
    connectionId             : PropTypes.string,
    onSetComponent           : PropTypes.func,
    // Implicit actions
    onStartCheckingConnection: PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = {
      isInProgress: false,
    };
    this.validatorRules = validatorRules;
    this.inputs = [];
    this.pinFields = [];
    times(constants.BLUETOOTH_PIN_LENGTH, (i) => this.pinFields.push(`pin_${i}`));
  }


  componentDidUpdate(prevProps) {
    const { connectionId, isInProgress, hasErrors } = this.props;
    if (prevProps.isInProgress !== isInProgress && !isInProgress && !hasErrors) {
      this.props.onStartCheckingConnection(connectionId);
      this.props.onSetComponent('Downloading');
    }
  }


  componentWillUnmount() {
    this.props.onClearForm();
  }


  onChangeDeviceType() {
    this.props.onSetComponent('ChooseConnection');
  }


  onChange(evt, i, id) {
    const { value } = evt.target;
    if (value === '') {
      this.props.onSetFormValue({ id, value });
      return;
    }
    const numberValue = Number(value);
    if (isNaN(numberValue)) {
      return;
    }
    this.props.onSetFormValue({ id, value: numberValue });
    const nextInput = get(this.inputs, i + 1);
    if (nextInput) {
      nextInput.focus();
    }
  }


  onSetInputRef(el, i) {
    this.inputs[i] = el;
  }


  onSubmit() {
    this.props.onFormProcessing();
    let pin = '';
    for (let i = 0; i < constants.BLUETOOTH_PIN_LENGTH; i++) {
      const id = `pin_${i}`;
      const value = get(this.props.formValues, ['values', id]);
      pin += value;
    }
    const values = get(this.props.formValues, 'values', {});
    const { validatedValues, errors } = Validator.run({ ...values, pin }, validatorRules);
    if (errors) {
      this.props.onFormErrors(errors);
      return;
    }
    this.props.onSubmit(validatedValues);
  }


  get isDisabled() {
    const values = get(this.props.formValues, 'values', {});
    const codeValues = pick(values, this.pinFields);
    const filteredValues = filter(valuesIn(codeValues), (value) => value !== '');
    return filteredValues.length !== constants.BLUETOOTH_PIN_LENGTH;
  }


  renderActions() {
    const { isInProgress } = this.state;
    return (
      <div className="modal__actions">
        <Button
          type="submit"
          styleModifier="primary"
          labelMessage={messages.buttons.submitPin}
          className="btn--block btn--filled"
          isDisabled={this.isDisabled}
          isInProgress={isInProgress}
        />
      </div>
    );
  }


  renderDigit(i) {
    const id = `pin_${i}`;
    const value = get(this.props.formValues, ['values', id], '');
    return (
      <input
        key={id}
        id={id}
        // type="password"
        value={value}
        className={`form-control ${styles.pin__digit}`}
        autoComplete="off"
        autoFocus={!i} // eslint-disable-line jsx-a11y/no-autofocus
        maxLength={1}
        onChange={(evt) => this.onChange(evt, i, id)}
        ref={(el) => this.onSetInputRef(el, i)}
      />
    );
  }


  render() {
    return (
      <div className={`${styles.modal__content} h-auto`}>
        <h3 className="modal__subheader "><FormattedMessage {...messages.bluetoothPin.header} /></h3>
        <img src="/assets/svg/bluetooth-pin.svg" className="mt-4 mb-3" alt="" />
        <Form onSubmit={() => this.onSubmit()} className={styles.pinForm}>
          <div className="form-group">
            <label className="form-label" htmlFor="pin_0">
              <FormattedMessage {...messages.labels.pin} />
            </label>
            <div className={styles.pin}>
              { times(constants.BLUETOOTH_PIN_LENGTH, (i) => this.renderDigit(i)) }
            </div>
          </div>
          {this.renderActions()}
        </Form>
        <div className={styles.divider}>
          <span className={styles.divider__text}><FormattedMessage {...messages.infos.or} /></span>
        </div>
        <Button
          styleModifier="transparent"
          labelMessage={messages.buttons.changeDeviceType}
          className="btn--no-size text--primary m-0"
          onClick={() => this.onChangeDeviceType()}
        />
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  connectionId: selectors.connectionId(state),
  isInProgress: selectors.isSendBluetoothPinInProgress(state),
  hasErrors   : selectors.hasSendBluetoothPinErrors(state),
  formValues  : App.selectors.formSelector(constants.BLUETOOTH_PIN_FORM)(state),
});


const mapDispatchToProps = (dispatch) => {
  const formName = constants.BLUETOOTH_PIN_FORM;
  return {
    onSubmit                 : (values) => dispatch(actions.sendBluetoothPin(values)),
    onStartCheckingConnection: (connectionId) => dispatch(actions.startCheckingConnection(connectionId)),
    onSetFormValue           : (input) => dispatch(App.actions.setFormValue({ formName, input })),
    onSetFormValues          : (values) => dispatch(App.actions.setFormValues({ formName, values })),
    onFormErrors             : (errors) => dispatch(App.actions.setFormErrors({ formName, errors })),
    onFormProcessing         : () => dispatch(App.actions.startFormProcessing(formName)),
    onClearForm              : () => dispatch(App.actions.clearForm(formName)),
  };
};


const ConnectedBluetoothPin = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BluetoothPin);


export default ConnectedBluetoothPin;
