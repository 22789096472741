import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import isNull from 'lodash/isNull';
import get from 'lodash/get';
import { FormattedMessage } from 'react-intl';
import { AppContext } from 'context';
import Avatar from 'components/Avatar';
import Button from 'components/Form/Button';
import Account from 'modules/Account';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import messages from '../../messages';
import styles from './ClinicClearData.pcss';


class ClinicClearData extends React.PureComponent {

  static contextType = AppContext;

  static propTypes = {
    // Explicit props
    activeClinicMembership  : Account.shapes.clinicMembership,
    // Implicit props
    patientsCount           : PropTypes.number,
    isInProgress            : PropTypes.bool,
    hasErrors               : PropTypes.bool,
    // Implicit actions
    onRemoveData            : PropTypes.func,
    onPause                 : PropTypes.func,
    onFetchClinicMemberships: PropTypes.func,
  };


  static getDerivedStateFromProps(props, state) {
    const { isInProgress } = props;
    if (state.isPausing && !isInProgress) {
      return {
        isPausing: false,
      };
    }
    return null;
  }


  constructor(props) {
    super(props);
    this.state = {
      isPausing: false,
    };
  }


  componentDidUpdate(prevProps) {
    const { isInProgress } = this.props;
    if (prevProps.isInProgress !== isInProgress && !isInProgress) {
      window.removeEventListener('beforeunload', this.onUnload);
    }
  }


  componentWillUnmount() {
    const { patientsCount, isInProgress, hasErrors } = this.props;
    if (this.props.isInProgress) {
      this.onPause();
    }
    if (patientsCount === 0 && !isInProgress && !hasErrors) {
      this.props.onFetchClinicMemberships();
    }
  }


  onRemoveData() {
    const profilesReferenceKey = get(this.props.activeClinicMembership, 'clinic.profilesReferenceKey');
    if (process.env.BROWSER && profilesReferenceKey) {
      this.props.onRemoveData(this.props.activeClinicMembership);
      window.addEventListener('beforeunload', this.onUnload);
    }
  }


  onPause() {
    this.setState({ isPausing: true });
    this.props.onPause();
    window.removeEventListener('beforeunload', this.onUnload);
  }


  onUnload(evt) {
    evt.preventDefault();
    evt.returnValue = '';
  }


  renderLogo(clinic) {
    const { logo, name } = clinic;

    return (
      <div className={styles.avatar}>
        <Avatar
          avatarImg={logo}
          name={name}
          className={styles.avatar__imgWrapper}
          imgClassName={styles.avatar__img}
          initialsClassName={styles.avatar__initials}
        />
      </div>
    );
  }


  renderAction() {
    const { patientsCount, isInProgress } = this.props;
    const profilesReferenceKey = get(this.props.activeClinicMembership, 'clinic.profilesReferenceKey');

    return (
      <Button
        styleModifier="primary"
        labelMessage={messages.buttons[isInProgress ? 'pause' : 'start']}
        className="btn--filled px-4"
        isDisabled={!profilesReferenceKey || patientsCount === 0}
        isInProgress={this.state.isPausing}
        onClick={() => (isInProgress ? this.onPause() : this.onRemoveData())}
      />
    );
  }


  renderRow(labelMessage, value, isActive = false) {
    return (
      <div className={`row justify-content-between my-4 ${!isActive && 'text--muted'}`}>
        <div className="col-auto"><FormattedMessage {...labelMessage} /></div>
        <div className="col-auto text-right">{ value }</div>
      </div>
    );
  }


  render() {
    const { clinic } = this.props.activeClinicMembership;
    const { patientsCount, isInProgress, hasErrors } = this.props;
    const b = (chunk) => <b>{ chunk }</b>;

    return (
      <div className={styles.container}>
        <div className={styles.containerInner}>
          <div className="content">
            <div className={styles.root}>
              { this.renderLogo(clinic) }
              <p className={styles.clinicName}>{ clinic.name }</p>
              <p className={styles.info}><FormattedMessage {...messages.infos.removeClinicData} values={{ b }} /></p>
              { this.renderAction() }
              <div className={styles.steps}>
                { this.renderRow(
                  messages.labels.searchingForPatients,
                  !isNull(patientsCount) && 'Done',
                  isInProgress || !isNull(patientsCount),
                ) }
                { this.renderRow(
                  messages.labels.removingPatients,
                  patientsCount === 0 ? 'Done' : patientsCount,
                  !isNull(patientsCount),
                ) }
                { this.renderRow(
                  messages.labels.clearingClinicData,
                  patientsCount === 0 && !isInProgress && !hasErrors && 'Done',
                  patientsCount === 0,
                ) }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  patientsCount         : selectors.patientsCount(state),
  isInProgress          : selectors.isRemoveClinicDataInProgress(state),
  hasErrors             : selectors.hasRemoveClinicDataErrors(state),
  activeClinicMembership: Account.selectors.activeClinicMembership(state),
});


const mapDispatchToProps = (dispatch) => ({
  onRemoveData            : (clinicMembership) => dispatch(actions.removeClinicData(clinicMembership)),
  onPause                 : () => dispatch(actions.removeClinicDataPause()),
  onFetchClinicMemberships: () => dispatch(Account.actions.fetchMemberships()),
});


const ConnectedClinicClearData = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClinicClearData);


export default withStyles(styles)(ConnectedClinicClearData);
