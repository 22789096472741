import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import { registerAction, unregisterAction } from 'helpers/reducerTools';
import { SET_NEW_CONFIGURATION_VERSION } from 'modules/App/actionTypes';
import * as actionTypes from './actionTypes';


const initialState = {
  isAuthenticated                : false,
  passphrase                     : null,
  invitationCode                 : null,
  account                        : null,
  accountCCAccessToken           : null,
  activeProfileType              : null,
  activeClinicMembershipId       : null,
  activeOrganizationUID          : null,
  activeHcpInvitationId          : null,
  hcpProfile                     : null,
  patientProfile                 : null,
  cgProfile                      : null,
  countrySettings                : null,
  hcpCustomIdentifiers           : [],
  patientDataFromInvitation      : null,
  patientHealthDataFromInvitation: null,
  payers                         : [],
  metricsUnits                   : null,
  hasElevatedPermissions         : false,
  elevatedPermissionsMode        : null,
  gdprData                       : {},
  tokenExpired                   : false,
  changePasswordReset            : false,
  fetching                       : [],
  sending                        : [],
  errors                         : [],
};


export default function reducer(state = { ...initialState }, action) {

  switch (action.type) {

    case actionTypes.GET_ME: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.GET_ME),
        errors  : unregisterAction(state.errors, actionTypes.GET_ME),
      };
    }
    case actionTypes.GET_ME_SUCCESS: {
      const {
        isAuthenticated,
        account,
        hcpProfile,
        patientProfile,
        cgProfile,
        metricsUnits,
      } = action.payload;

      // TODO: fix when BE exposes proper fields on updateAccountSettings EP
      if (metricsUnits) {
        metricsUnits.cholesterol = metricsUnits.bloodGlucoseConcentration;
        metricsUnits.triglycerides = metricsUnits.bloodGlucoseConcentration;
      }

      return {
        ...state,
        isAuthenticated: !!isAuthenticated,
        account,
        hcpProfile,
        patientProfile,
        cgProfile,
        metricsUnits,
        fetching       : unregisterAction(state.fetching, actionTypes.GET_ME),
      };
    }
    case actionTypes.GET_ME_ERROR: {
      return {
        ...state,
        isAuthenticated: false,
        fetching       : unregisterAction(state.fetching, actionTypes.GET_ME),
        errors         : registerAction(state.errors, actionTypes.GET_ME),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_ACTIVE_PROFILE_TYPE: {
      const { activeProfileType } = action.payload;
      return {
        ...state,
        activeProfileType,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_PATIENT_DATA_FROM_INVITATION: {
      return {
        ...state,
        patientDataFromInvitation: null,
        fetching                 : registerAction(state.fetching, actionTypes.FETCH_PATIENT_DATA_FROM_INVITATION),
        errors                   : unregisterAction(state.errors, actionTypes.FETCH_PATIENT_DATA_FROM_INVITATION),
      };
    }
    case actionTypes.FETCH_PATIENT_DATA_FROM_INVITATION_SUCCESS: {
      const { patientDataFromInvitation } = action.payload;
      return {
        ...state,
        patientDataFromInvitation,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_PATIENT_DATA_FROM_INVITATION),
      };
    }
    case actionTypes.FETCH_PATIENT_DATA_FROM_INVITATION_ERROR: {
      return {
        ...state,
        patientDataFromInvitation: null,
        fetching                 : unregisterAction(state.fetching, actionTypes.FETCH_PATIENT_DATA_FROM_INVITATION),
        errors                   : registerAction(state.errors, actionTypes.FETCH_PATIENT_DATA_FROM_INVITATION),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_PATIENT_HEALTH_DATA_FROM_INVITATION: {
      return {
        ...state,
        patientDataFromInvitation: null,
        fetching                 : registerAction(state.fetching, actionTypes.FETCH_PATIENT_HEALTH_DATA_FROM_INVITATION),
        errors                   : unregisterAction(state.errors, actionTypes.FETCH_PATIENT_HEALTH_DATA_FROM_INVITATION),
      };
    }
    case actionTypes.FETCH_PATIENT_HEALTH_DATA_FROM_INVITATION_SUCCESS: {
      const { patientHealthDataFromInvitation } = action.payload;
      return {
        ...state,
        patientHealthDataFromInvitation,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_PATIENT_HEALTH_DATA_FROM_INVITATION),
      };
    }
    case actionTypes.FETCH_PATIENT_HEALTH_DATA_FROM_INVITATION_ERROR: {
      return {
        ...state,
        patientDataFromInvitation: null,
        fetching                 : unregisterAction(state.fetching, actionTypes.FETCH_PATIENT_HEALTH_DATA_FROM_INVITATION),
        errors                   : registerAction(state.errors, actionTypes.FETCH_PATIENT_HEALTH_DATA_FROM_INVITATION),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_ACTIVE_CLINIC_MEMBERSHIP_ID: {
      const { activeClinicMembershipId, activeOrganizationUID } = action.payload;
      return {
        ...state,
        activeClinicMembershipId,
        activeOrganizationUID,
        activeHcpInvitationId: null,
      };
    }

    case actionTypes.SET_ACTIVE_CLINIC_MEMBERSHIP_CLEARED: {
      const { activeClinicMembershipId, hcpProfile } = state;
      const idx = findIndex(hcpProfile.clinicHcpMemberships, { clinicHcpMembershipId: activeClinicMembershipId });
      if (idx < 0) {
        return state;
      }

      const clinicHcpMemberships = [...hcpProfile.clinicHcpMemberships];
      const clinicMembership = { ...clinicHcpMemberships[idx] };
      clinicMembership.clinic.clinicStatus = 'Cleared';
      clinicHcpMemberships[idx] = clinicMembership;

      return {
        ...state,
        hcpProfile: { ...state.hcpProfile, clinicHcpMemberships },
      };
    }

    // BackOffice UPDATE -----------------------------------------------------------------------------------------------

    case SET_NEW_CONFIGURATION_VERSION: {
      const { payers, countrySettings, supportedDevices } = action.payload;
      const patientProfile = state.patientProfile
        ? { ...state.patientProfile, supportedDevices }
        : state.patientProfile;
      return {
        ...state,
        payers,
        countrySettings,
        patientProfile,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SIGN_IN: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.SIGN_IN),
        errors : unregisterAction(state.errors, actionTypes.SIGN_IN),
      };
    }
    case actionTypes.SIGN_IN_SUCCESS: {
      // const {  } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        sending        : unregisterAction(state.sending, actionTypes.SIGN_IN),
      };
    }
    case actionTypes.SIGN_IN_ERROR: {
      return {
        ...state,
        isAuthenticated: false,
        sending        : unregisterAction(state.sending, actionTypes.SIGN_IN),
        errors         : registerAction(state.errors, actionTypes.SIGN_IN),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.AUTH_CONTOUR_CLOUD: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.AUTH_CONTOUR_CLOUD),
        errors : unregisterAction(state.errors, actionTypes.AUTH_CONTOUR_CLOUD),
      };
    }
    case actionTypes.AUTH_CONTOUR_CLOUD_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true,
        sending        : unregisterAction(state.sending, actionTypes.AUTH_CONTOUR_CLOUD),
      };
    }
    case actionTypes.AUTH_CONTOUR_CLOUD_ERROR: {
      return {
        ...state,
        isAuthenticated: false,
        sending        : unregisterAction(state.sending, actionTypes.AUTH_CONTOUR_CLOUD),
        errors         : registerAction(state.errors, actionTypes.AUTH_CONTOUR_CLOUD),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SIGN_OUT: {
      return {
        ...state,
        isAuthenticated: false,
        sending        : registerAction(state.sending, actionTypes.SIGN_OUT),
        errors         : unregisterAction(state.errors, actionTypes.SIGN_OUT),
      };
    }
    case actionTypes.SIGN_OUT_SUCCESS: {
      return {
        ...initialState,
      };
    }
    case actionTypes.SIGN_OUT_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.SIGN_OUT),
        errors : registerAction(state.errors, actionTypes.SIGN_OUT),
      };
    }

    //------------------------------------------------------------------------------------------------------------------
    case actionTypes.SET_ELEVATED_PERMISSIONS_MODE: {
      return {
        ...state,
        elevatedPermissionsMode: action.payload,
        hasElevatedPermissions : false,
      };
    }
    case actionTypes.ELEVATE_PERMISSIONS: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.ELEVATE_PERMISSIONS),
        errors : unregisterAction(state.errors, actionTypes.ELEVATE_PERMISSIONS),
      };
    }
    case actionTypes.ELEVATE_PERMISSIONS_SUCCESS: {
      return {
        ...state,
        hasElevatedPermissions: true,
        sending               : unregisterAction(state.sending, actionTypes.ELEVATE_PERMISSIONS),
      };
    }
    case actionTypes.ELEVATE_PERMISSIONS_ERROR: {
      return {
        ...state,
        hasElevatedPermissions: false,
        sending               : unregisterAction(state.sending, actionTypes.ELEVATE_PERMISSIONS),
        errors                : registerAction(state.errors, actionTypes.ELEVATE_PERMISSIONS),
      };
    }
    case actionTypes.ELEVATE_PERMISSIONS_TIMEOUT: {
      return {
        ...state,
        hasElevatedPermissions: false,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.GENERATE_KEYS_PEM: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.GENERATE_KEYS_PEM),
        errors : unregisterAction(state.errors, actionTypes.GENERATE_KEYS_PEM),
      };
    }
    case actionTypes.GENERATE_KEYS_PEM_SUCCESS: {
      const { prvKeyPem, pubKeyPem, passphrase, encryptedPassphrase } = action.payload;
      const account = { ...state.account, encryptedPassphrase, keyPair: { prvKeyPem, pubKeyPem } };
      return {
        ...state,
        account,
        passphrase,
        sending: unregisterAction(state.sending, actionTypes.GENERATE_KEYS_PEM),
      };
    }
    case actionTypes.GENERATE_KEYS_PEM_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.GENERATE_KEYS_PEM),
        errors : registerAction(state.errors, actionTypes.GENERATE_KEYS_PEM),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_KEYS: {
      const { prvKeyPem, pubKeyPem, encryptedPassphrase } = action.payload;
      const account = { ...state.account, encryptedPassphrase, keyPair: { prvKeyPem, pubKeyPem } };
      return {
        ...state,
        account,
        sending: unregisterAction(state.sending, actionTypes.GENERATE_KEYS_PEM),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.GET_PASSPHRASE_SUCCESS: {
      const { passphrase } = action.payload;
      return {
        ...state,
        passphrase,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.UPDATE_ACCOUNT_SETTINGS: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.UPDATE_ACCOUNT_SETTINGS),
        errors : unregisterAction(state.errors, actionTypes.UPDATE_ACCOUNT_SETTINGS),
      };
    }
    case actionTypes.UPDATE_ACCOUNT_SETTINGS_SUCCESS: {
      const { metricsUnits, account } = state;

      // TODO: fix when BE exposes proper fields on updateAccountSettings EP
      const concentrationUnit = get(action.payload, 'settings.glucoseLevelUnitTypes');
      metricsUnits.bloodGlucoseConcentration = concentrationUnit;
      metricsUnits.cholesterol = concentrationUnit;
      metricsUnits.triglycerides = concentrationUnit;

      const settings = {
        ...account.settings,
        ...action.payload.settings,
      };
      return {
        ...state,
        metricsUnits,
        account: { ...state.account, settings },
        sending: unregisterAction(state.sending, actionTypes.UPDATE_ACCOUNT_SETTINGS),
      };
    }
    case actionTypes.UPDATE_ACCOUNT_SETTINGS_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.UPDATE_ACCOUNT_SETTINGS),
        errors : registerAction(state.errors, actionTypes.UPDATE_ACCOUNT_SETTINGS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.DELETE_ACCOUNT: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.DELETE_ACCOUNT),
        errors : unregisterAction(state.errors, actionTypes.DELETE_ACCOUNT),
      };
    }
    case actionTypes.DELETE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.DELETE_ACCOUNT),
      };
    }
    case actionTypes.DELETE_ACCOUNT_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.DELETE_ACCOUNT),
        errors : registerAction(state.errors, actionTypes.DELETE_ACCOUNT),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.CREATE_PATIENT_PROFILE: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.CREATE_PATIENT_PROFILE),
        errors : unregisterAction(state.errors, actionTypes.CREATE_PATIENT_PROFILE),
      };
    }
    case actionTypes.CREATE_PATIENT_PROFILE_SUCCESS: {
      const { patientProfile } = action.payload;
      return {
        ...state,
        patientProfile,
        patientDataFromInvitation: null,
        sending                  : unregisterAction(state.sending, actionTypes.CREATE_PATIENT_PROFILE),
      };
    }
    case actionTypes.CREATE_PATIENT_PROFILE_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.CREATE_PATIENT_PROFILE),
        errors : registerAction(state.errors, actionTypes.CREATE_PATIENT_PROFILE),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.UPDATE_PATIENT_PROFILE: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.UPDATE_PATIENT_PROFILE),
        errors : unregisterAction(state.errors, actionTypes.UPDATE_PATIENT_PROFILE),
      };
    }

    case actionTypes.UPDATE_PATIENT_PROFILE_SUCCESS: {
      const { patientProfile } = action.payload;
      return {
        ...state,
        patientProfile: { ...state.patientProfile, ...patientProfile },
        sending       : unregisterAction(
          state.sending,
          actionTypes.UPDATE_PATIENT_PROFILE,
        ),
      };
    }

    case actionTypes.UPDATE_PATIENT_PROFILE_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.UPDATE_PATIENT_PROFILE),
        errors : registerAction(state.errors, actionTypes.UPDATE_PATIENT_PROFILE),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.CONNECT_TO_PATIENT_STORAGE: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.CONNECT_TO_PATIENT_STORAGE),
        errors : unregisterAction(state.errors, actionTypes.CONNECT_TO_PATIENT_STORAGE),
      };
    }

    case actionTypes.CONNECT_TO_PATIENT_STORAGE_SUCCESS: {
      const { patientProfile } = action.payload;
      return {
        ...state,
        patientProfile: { ...state.patientProfile, ...patientProfile },
        sending       : unregisterAction(
          state.sending,
          actionTypes.CONNECT_TO_PATIENT_STORAGE,
        ),
      };
    }

    case actionTypes.CONNECT_TO_PATIENT_STORAGE_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.CONNECT_TO_PATIENT_STORAGE),
        errors : registerAction(state.errors, actionTypes.CONNECT_TO_PATIENT_STORAGE),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_COUNTRY_SETTINGS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_COUNTRY_SETTINGS),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_COUNTRY_SETTINGS),
      };
    }
    case actionTypes.FETCH_COUNTRY_SETTINGS_SUCCESS: {
      const { countrySettings } = action.payload;
      return {
        ...state,
        countrySettings,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_COUNTRY_SETTINGS),
      };
    }
    case actionTypes.FETCH_COUNTRY_SETTINGS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_COUNTRY_SETTINGS),
        errors  : registerAction(state.errors, actionTypes.FETCH_COUNTRY_SETTINGS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_PATIENT_PROFILE_SUPPORTED_DEVICES: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_PATIENT_PROFILE_SUPPORTED_DEVICES),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_PATIENT_PROFILE_SUPPORTED_DEVICES),
      };
    }
    case actionTypes.FETCH_PATIENT_PROFILE_SUPPORTED_DEVICES_SUCCESS: {
      const { supportedDevices } = action.payload;
      const patientProfile = { ...state.patientProfile, supportedDevices };
      return {
        ...state,
        patientProfile,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_PATIENT_PROFILE_SUPPORTED_DEVICES),
      };
    }
    case actionTypes.FETCH_PATIENT_PROFILE_SUPPORTED_DEVICES_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_PATIENT_PROFILE_SUPPORTED_DEVICES),
        errors  : registerAction(state.errors, actionTypes.FETCH_PATIENT_PROFILE_SUPPORTED_DEVICES),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_PAYERS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_PAYERS),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_PAYERS),
      };
    }
    case actionTypes.FETCH_PAYERS_SUCCESS: {
      const { payers } = action.payload;
      return {
        ...state,
        payers,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_PAYERS),
      };
    }
    case actionTypes.FETCH_PAYERS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_PAYERS),
        errors  : registerAction(state.errors, actionTypes.FETCH_PAYERS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.CREATE_CG_PROFILE: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.CREATE_CG_PROFILE),
        errors : unregisterAction(state.errors, actionTypes.CREATE_CG_PROFILE),
      };
    }
    case actionTypes.CREATE_CG_PROFILE_SUCCESS: {
      const { cgProfile } = action.payload;
      return {
        ...state,
        cgProfile,
        sending: unregisterAction(state.sending, actionTypes.CREATE_CG_PROFILE),
      };
    }
    case actionTypes.CREATE_CG_PROFILE_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.CREATE_CG_PROFILE),
        errors : registerAction(state.errors, actionTypes.CREATE_CG_PROFILE),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.UPDATE_CG_PROFILE: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.UPDATE_CG_PROFILE),
        errors : unregisterAction(state.errors, actionTypes.UPDATE_CG_PROFILE),
      };
    }
    case actionTypes.UPDATE_CG_PROFILE_SUCCESS: {
      const { cgProfile } = action.payload;
      return {
        ...state,
        cgProfile: { ...state.cgProfile, ...cgProfile },
        sending  : unregisterAction(state.sending, actionTypes.UPDATE_CG_PROFILE),
      };
    }
    case actionTypes.UPDATE_CG_PROFILE_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.UPDATE_CG_PROFILE),
        errors : registerAction(state.errors, actionTypes.UPDATE_CG_PROFILE),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.CREATE_HCP_PROFILE: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.CREATE_HCP_PROFILE),
        errors : unregisterAction(state.errors, actionTypes.CREATE_HCP_PROFILE),
      };
    }
    case actionTypes.CREATE_HCP_PROFILE_SUCCESS: {
      const { hcpProfile } = action.payload;
      return {
        ...state,
        hcpProfile,
        sending: unregisterAction(state.sending, actionTypes.CREATE_HCP_PROFILE),
      };
    }
    case actionTypes.CREATE_HCP_PROFILE_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.CREATE_HCP_PROFILE),
        errors : registerAction(state.errors, actionTypes.CREATE_HCP_PROFILE),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.UPDATE_HCP_PROFILE: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.UPDATE_HCP_PROFILE),
        errors : unregisterAction(state.errors, actionTypes.UPDATE_HCP_PROFILE),
      };
    }
    case actionTypes.UPDATE_HCP_PROFILE_SUCCESS: {
      const { hcpProfile } = action.payload;
      return {
        ...state,
        hcpProfile: { ...state.hcpProfile, ...hcpProfile },
        sending   : unregisterAction(state.sending, actionTypes.UPDATE_HCP_PROFILE),
      };
    }
    case actionTypes.UPDATE_HCP_PROFILE_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.UPDATE_HCP_PROFILE),
        errors : registerAction(state.errors, actionTypes.UPDATE_HCP_PROFILE),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_HCP_CUSTOM_IDENTIFIERS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_HCP_CUSTOM_IDENTIFIERS),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_HCP_CUSTOM_IDENTIFIERS),
      };
    }
    case actionTypes.FETCH_HCP_CUSTOM_IDENTIFIERS_SUCCESS: {
      const { hcpCustomIdentifiers } = action.payload;
      return {
        ...state,
        hcpCustomIdentifiers,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_HCP_CUSTOM_IDENTIFIERS),
      };
    }
    case actionTypes.FETCH_HCP_CUSTOM_IDENTIFIERS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_HCP_CUSTOM_IDENTIFIERS),
        errors  : registerAction(state.errors, actionTypes.FETCH_HCP_CUSTOM_IDENTIFIERS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_MEMBERSHIPS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_MEMBERSHIPS),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_MEMBERSHIPS),
      };
    }
    case actionTypes.FETCH_MEMBERSHIPS_SUCCESS: {
      const { clinicHcpMemberships } = action.payload;
      return {
        ...state,
        hcpProfile: { ...state.hcpProfile, clinicHcpMemberships },
        fetching  : unregisterAction(state.fetching, actionTypes.FETCH_MEMBERSHIPS),
      };
    }
    case actionTypes.FETCH_MEMBERSHIPS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_MEMBERSHIPS),
        errors  : registerAction(state.errors, actionTypes.FETCH_MEMBERSHIPS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.CHECK_MEMBERSHIPS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.CHECK_MEMBERSHIPS),
        errors  : unregisterAction(state.errors, actionTypes.CHECK_MEMBERSHIPS),
      };
    }
    case actionTypes.CHECK_MEMBERSHIPS_SUCCESS: {
      const { organizationMembershipsWithoutClinics } = action.payload;
      return {
        ...state,
        hcpProfile: { ...state.hcpProfile, organizationMembershipsWithoutClinics },
        fetching  : unregisterAction(state.fetching, actionTypes.CHECK_MEMBERSHIPS),
      };
    }
    case actionTypes.CHECK_MEMBERSHIPS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.CHECK_MEMBERSHIPS),
        errors  : registerAction(state.errors, actionTypes.CHECK_MEMBERSHIPS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.VALIDATE_MEMBERSHIPS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.VALIDATE_MEMBERSHIPS),
        errors  : unregisterAction(state.errors, actionTypes.VALIDATE_MEMBERSHIPS),
      };
    }

    case actionTypes.VALIDATE_MEMBERSHIPS_SUCCESS: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.VALIDATE_MEMBERSHIPS),
      };
    }

    case actionTypes.VALIDATE_MEMBERSHIPS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.VALIDATE_MEMBERSHIPS),
        errors  : registerAction(state.errors, actionTypes.VALIDATE_MEMBERSHIPS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.CONNECT_TO_CLINIC: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.CONNECT_TO_CLINIC),
        errors : unregisterAction(state.errors, actionTypes.CONNECT_TO_CLINIC),
      };
    }
    case actionTypes.CONNECT_TO_CLINIC_SUCCESS: {
      const { clinicHcpMembershipId, accessToken, passphrase, profilesReferenceKey, supportedDevices } = action.payload;
      if (!state.hcpProfile) {
        throw new Error('No HCP profile'); // @TODO: LocalError?
      }

      const clinicHcpMemberships = [...get(state.hcpProfile, 'clinicHcpMemberships', [])];
      const clinicMembershipIdx = findIndex(
        clinicHcpMemberships,
        (membership) => membership.clinicHcpMembershipId === clinicHcpMembershipId,
      );
      const clinicMembership = clinicHcpMemberships[clinicMembershipIdx];
      const { clinic } = clinicMembership;
      clinicHcpMemberships[clinicMembershipIdx] = {
        ...clinicMembership,
        accessToken: accessToken || clinicMembership.accessToken,
        passphrase : passphrase || clinicMembership.passphrase,
        clinic     : {
          ...clinic,
          profilesReferenceKey: profilesReferenceKey || clinic.profilesReferenceKey,
          supportedDevices,
        },
      };
      const hcpProfile = { ...state.hcpProfile, clinicHcpMemberships };
      return {
        ...state,
        hcpProfile,
        sending: unregisterAction(state.sending, actionTypes.CONNECT_TO_CLINIC),
      };
    }
    case actionTypes.CONNECT_TO_CLINIC_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.CONNECT_TO_CLINIC),
        errors : registerAction(state.errors, actionTypes.CONNECT_TO_CLINIC),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.GDPR_FETCH_ACCOUNT_DUMP: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.GDPR_FETCH_ACCOUNT_DUMP),
        errors  : unregisterAction(state.errors, actionTypes.GDPR_FETCH_ACCOUNT_DUMP),
      };
    }
    case actionTypes.GDPR_FETCH_ACCOUNT_DUMP_SUCCESS: {
      const { account } = action.payload;
      return {
        ...state,
        gdprData: { ...state.gdprData, account },
        fetching: unregisterAction(state.fetching, actionTypes.GDPR_FETCH_ACCOUNT_DUMP),
      };
    }
    case actionTypes.GDPR_FETCH_ACCOUNT_DUMP_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.GDPR_FETCH_ACCOUNT_DUMP),
        errors  : registerAction(state.errors, actionTypes.GDPR_FETCH_ACCOUNT_DUMP),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.GDPR_SET_PHI_SET: {
      const { phiSet } = action.payload;
      return {
        ...state,
        gdprData: { ...state.gdprData, phiSet },
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.GDPR_SET_CLOUD_DRIVE_DUMP: {
      const { dump } = action.payload;
      const { readingsMonthlyBatches, cgmReadingsMonthlyBatches, measurementsMonthlyBatches, imports } = dump;
      return {
        ...state,
        gdprData: {
          ...state.gdprData,
          readingsMonthlyBatches,
          cgmReadingsMonthlyBatches,
          measurementsMonthlyBatches,
          imports,
        },
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.GDPR_CLEAR_DATA: {
      return {
        ...state,
        gdprData: {},
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.GDPR_SAVE_TO_FILE: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.GDPR_SAVE_TO_FILE),
        errors  : unregisterAction(state.errors, actionTypes.GDPR_SAVE_TO_FILE),
      };
    }
    case actionTypes.GDPR_SAVE_TO_FILE_SUCCESS: {
      return {
        ...state,
        gdprData: {},
        fetching: unregisterAction(state.fetching, actionTypes.GDPR_SAVE_TO_FILE),
      };
    }
    case actionTypes.GDPR_SAVE_TO_FILE_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.GDPR_SAVE_TO_FILE),
        errors  : registerAction(state.errors, actionTypes.GDPR_SAVE_TO_FILE),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_CHANGE_PASSWORD_RESET: {
      const { changePasswordReset } = action.payload;
      return {
        ...state,
        changePasswordReset,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.UPDATE_NOTIFICATIONS_SETTINGS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.UPDATE_NOTIFICATIONS_SETTINGS),
        errors  : unregisterAction(state.errors, actionTypes.UPDATE_NOTIFICATIONS_SETTINGS),
      };
    }
    case actionTypes.UPDATE_NOTIFICATIONS_SETTINGS_SUCCESS: {
      const { notificationsSettings } = action.payload;
      if (!state.account || !state.account.settings) {
        return state;
      }
      const stateNotifications = state.account.settings.notifications;
      const mergedNotifications = {};
      Object.keys(notificationsSettings).forEach(
        (item) => {
          mergedNotifications[item] = {
            ...stateNotifications[item],
            push: notificationsSettings[item],
          };
        }
      );


      return {
        ...state,
        account: {
          ...state.account,
          settings: {
            ...state.account.settings,
            notifications: {
              ...stateNotifications,
              ...mergedNotifications,
            },
          },
        },
        fetching: unregisterAction(state.fetching, actionTypes.UPDATE_NOTIFICATIONS_SETTINGS),
      };
    }
    case actionTypes.UPDATE_NOTIFICATIONS_SETTINGS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.UPDATE_NOTIFICATIONS_SETTINGS),
        errors  : registerAction(state.errors, actionTypes.UPDATE_NOTIFICATIONS_SETTINGS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.GET_CC_ACCESS_TOKEN_SUCCESS: {
      const { accessToken } = action.payload;
      return {
        ...state,
        accountCCAccessToken: accessToken,
      };
    }
    case actionTypes.GET_CC_ACCESS_TOKEN_ERROR: {
      return {
        ...state,
        accountCCAccessToken: null,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    default: {
      return state;
    }

  }
}
