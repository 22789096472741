import { MODULE_ID } from './constants';


export const GET_ME = `${MODULE_ID}/GET_ME`;
export const GET_ME_SUCCESS = `${MODULE_ID}/GET_ME_SUCCESS`;
export const GET_ME_ERROR = `${MODULE_ID}/GET_ME_ERROR`;

export const FETCH_TOKEN = `${MODULE_ID}/FETCH_TOKEN`;

export const AUTH_CALLBACK = `${MODULE_ID}/AUTH_CALLBACK`;

export const CHANGE_AID_PASSWORD = `${MODULE_ID}/CHANGE_AID_PASSWORD`;
export const EDIT_AID_ACCOUNT = `${MODULE_ID}/EDIT_AID_ACCOUNT`;
export const ADD_AID_ORGANIZATION_MEMBERSHIP = `${MODULE_ID}/ADD_AID_ORGANIZATION_MEMBERSHIP`;
export const MANAGE_AID_ORGANIZATION_MEMBERSHIPS = `${MODULE_ID}/MANAGE_AID_ORGANIZATION_MEMBERSHIPS`;

export const VALIDATE_ACCOUNT = `${MODULE_ID}/VALIDATE_ACCOUNT`;

export const CHECK_PERMISSIONS = `${MODULE_ID}/CHECK_PERMISSIONS`;
export const CHECK_PERMISSIONS_END = `${MODULE_ID}/CHECK_PERMISSIONS_END`;

export const SET_ACTIVE_PROFILE_TYPE = `${MODULE_ID}/SET_ACTIVE_PROFILE_TYPE`;
export const SWITCH_PROFILE = `${MODULE_ID}/SWITCH_PROFILE`;

export const SET_ACTIVE_HCP_INVITATION_ID = `${MODULE_ID}/SET_ACTIVE_HCP_INVITATION_ID`;
export const SET_ACTIVE_CLINIC_MEMBERSHIP_ID = `${MODULE_ID}/SET_ACTIVE_CLINIC_MEMBERSHIP_ID`;
export const SET_ACTIVE_CLINIC_MEMBERSHIP_CLEARED = `${MODULE_ID}/SET_ACTIVE_CLINIC_MEMBERSHIP_CLEARED`;

export const FETCH_PATIENT_DATA_FROM_INVITATION = `${MODULE_ID}/FETCH_PATIENT_DATA_FROM_INVITATION`;
export const FETCH_PATIENT_DATA_FROM_INVITATION_SUCCESS = `${MODULE_ID}/FETCH_PATIENT_DATA_FROM_INVITATION_SUCCESS`;
export const FETCH_PATIENT_DATA_FROM_INVITATION_ERROR = `${MODULE_ID}/FETCH_PATIENT_DATA_FROM_INVITATION_ERROR`;

export const FETCH_PATIENT_HEALTH_DATA_FROM_INVITATION = `${MODULE_ID}/FETCH_PATIENT_HEALTH_DATA_FROM_INVITATION`;
export const FETCH_PATIENT_HEALTH_DATA_FROM_INVITATION_SUCCESS = `${MODULE_ID}/FETCH_PATIENT_HEALTH_DATA_FROM_INVITATION_SUCCESS`;
export const FETCH_PATIENT_HEALTH_DATA_FROM_INVITATION_ERROR = `${MODULE_ID}/FETCH_PATIENT_HEALTH_DATA_FROM_INVITATION_ERROR`;

export const SIGN_IN = `${MODULE_ID}/SIGN_IN`;
export const SIGN_IN_SUCCESS = `${MODULE_ID}/SIGN_IN_SUCCESS`;
export const SIGN_IN_ERROR = `${MODULE_ID}/SIGN_IN_ERROR`;

export const SIGN_OUT = `${MODULE_ID}/SIGN_OUT`;
export const SIGN_OUT_SUCCESS = `${MODULE_ID}/SIGN_OUT_SUCCESS`;
export const SIGN_OUT_ERROR = `${MODULE_ID}/SIGN_OUT_ERROR`;

export const SET_ELEVATED_PERMISSIONS_MODE = `${MODULE_ID}/SET_ELEVATED_PERMISSIONS_MODE`;

export const ELEVATE_PERMISSIONS = `${MODULE_ID}/ELEVATE_PERMISSIONS`;
export const ELEVATE_PERMISSIONS_SUCCESS = `${MODULE_ID}/ELEVATE_PERMISSIONS_SUCCESS`;
export const ELEVATE_PERMISSIONS_ERROR = `${MODULE_ID}/ELEVATE_PERMISSIONS_ERROR`;
export const ELEVATE_PERMISSIONS_TIMEOUT = `${MODULE_ID}/ELEVATE_PERMISSIONS_TIMEOUT`;

export const GENERATE_KEYS_PEM = `${MODULE_ID}/GENERATE_KEYS_PEM`;
export const GENERATE_KEYS_PEM_SUCCESS = `${MODULE_ID}/GENERATE_KEYS_PEM_SUCCESS`;
export const GENERATE_KEYS_PEM_ERROR = `${MODULE_ID}/GENERATE_KEYS_PEM_ERROR`;

export const SET_KEYS = `${MODULE_ID}/SET_KEYS`;

export const GET_PASSPHRASE = `${MODULE_ID}/GET_PASSPHRASE`;
export const GET_PASSPHRASE_SUCCESS = `${MODULE_ID}/GET_PASSPHRASE_SUCCESS`;
export const GET_PASSPHRASE_ERROR = `${MODULE_ID}/GET_PASSPHRASE_ERROR`;

export const UPDATE_ACCOUNT = `${MODULE_ID}/UPDATE_ACCOUNT`;
export const UPDATE_ACCOUNT_SUCCESS = `${MODULE_ID}/UPDATE_ACCOUNT_SUCCESS`;
export const UPDATE_ACCOUNT_ERROR = `${MODULE_ID}/UPDATE_ACCOUNT_ERROR`;

export const UPDATE_ACCOUNT_SETTINGS = `${MODULE_ID}/UPDATE_ACCOUNT_SETTINGS`;
export const UPDATE_ACCOUNT_SETTINGS_SUCCESS = `${MODULE_ID}/UPDATE_ACCOUNT_SETTINGS_SUCCESS`;
export const UPDATE_ACCOUNT_SETTINGS_ERROR = `${MODULE_ID}/UPDATE_ACCOUNT_SETTINGS_ERROR`;

export const DELETE_ACCOUNT = `${MODULE_ID}/DELETE_ACCOUNT`;
export const DELETE_ACCOUNT_SUCCESS = `${MODULE_ID}/DELETE_ACCOUNT_SUCCESS`;
export const DELETE_ACCOUNT_ERROR = `${MODULE_ID}/DELETE_ACCOUNT_ERROR`;

export const CREATE_PATIENT_PROFILE = `${MODULE_ID}/CREATE_PATIENT_PROFILE`;
export const CREATE_PATIENT_PROFILE_SUCCESS = `${MODULE_ID}/CREATE_PATIENT_PROFILE_SUCCESS`;
export const CREATE_PATIENT_PROFILE_ERROR = `${MODULE_ID}/CREATE_PATIENT_PROFILE_ERROR`;

export const UPDATE_PATIENT_PROFILE = `${MODULE_ID}/UPDATE_PATIENT_PROFILE`;
export const UPDATE_PATIENT_PROFILE_SUCCESS = `${MODULE_ID}/UPDATE_PATIENT_PROFILE_SUCCESS`;
export const UPDATE_PATIENT_PROFILE_ERROR = `${MODULE_ID}/UPDATE_PATIENT_PROFILE_ERROR`;

export const REAUTHORIZE_PATIENT_PROFILE_CLOUD_DRIVE = `${MODULE_ID}/REAUTHORIZE_PATIENT_PROFILE_CLOUD_DRIVE`;
export const REAUTHORIZE_PATIENT_PROFILE_CLOUD_DRIVE_SUCCESS = `${MODULE_ID}/REAUTHORIZE_PATIENT_PROFILE_CLOUD_DRIVE_SUCCESS`;
export const REAUTHORIZE_PATIENT_PROFILE_CLOUD_DRIVE_ERROR = `${MODULE_ID}/REAUTHORIZE_PATIENT_PROFILE_CLOUD_DRIVE_ERROR`;

export const FETCH_COUNTRY_SETTINGS = `${MODULE_ID}/FETCH_COUNTRY_SETTINGS`;
export const FETCH_COUNTRY_SETTINGS_SUCCESS = `${MODULE_ID}/FETCH_COUNTRY_SETTINGS_SUCCESS`;
export const FETCH_COUNTRY_SETTINGS_ERROR = `${MODULE_ID}/FETCH_COUNTRY_SETTINGS_ERROR`;

export const FETCH_PAYERS = `${MODULE_ID}/FETCH_PAYERS`;
export const FETCH_PAYERS_SUCCESS = `${MODULE_ID}/FETCH_PAYERS_SUCCESS`;
export const FETCH_PAYERS_ERROR = `${MODULE_ID}/FETCH_PAYERS_ERROR`;

export const FETCH_PATIENT_PROFILE_SUPPORTED_DEVICES = `${MODULE_ID}/FETCH_PATIENT_PROFILE_SUPPORTED_DEVICES`;
export const FETCH_PATIENT_PROFILE_SUPPORTED_DEVICES_SUCCESS = `${MODULE_ID}/FETCH_PATIENT_PROFILE_SUPPORTED_DEVICES_SUCCESS`;
export const FETCH_PATIENT_PROFILE_SUPPORTED_DEVICES_ERROR = `${MODULE_ID}/FETCH_PATIENT_PROFILE_SUPPORTED_DEVICES_ERROR`;

export const CONNECT_TO_PATIENT_STORAGE = `${MODULE_ID}/CONNECT_TO_PATIENT_STORAGE`;
export const CONNECT_TO_PATIENT_STORAGE_SUCCESS = `${MODULE_ID}/CONNECT_TO_PATIENT_STORAGE_SUCCESS`;
export const CONNECT_TO_PATIENT_STORAGE_ERROR = `${MODULE_ID}/CONNECT_TO_PATIENT_STORAGE_ERROR`;

export const CREATE_CG_PROFILE = `${MODULE_ID}/CREATE_CG_PROFILE`;
export const CREATE_CG_PROFILE_SUCCESS = `${MODULE_ID}/CREATE_CG_PROFILE_SUCCESS`;
export const CREATE_CG_PROFILE_ERROR = `${MODULE_ID}/CREATE_CG_PROFILE_ERROR`;

export const UPDATE_CG_PROFILE = `${MODULE_ID}/UPDATE_CG_PROFILE`;
export const UPDATE_CG_PROFILE_SUCCESS = `${MODULE_ID}/UPDATE_CG_PROFILE_SUCCESS`;
export const UPDATE_CG_PROFILE_ERROR = `${MODULE_ID}/UPDATE_CG_PROFILE_ERROR`;

export const CREATE_HCP_PROFILE = `${MODULE_ID}/CREATE_HCP_PROFILE`;
export const CREATE_HCP_PROFILE_SUCCESS = `${MODULE_ID}/CREATE_HCP_PROFILE_SUCCESS`;
export const CREATE_HCP_PROFILE_ERROR = `${MODULE_ID}/CREATE_HCP_PROFILE_ERROR`;

export const UPDATE_HCP_PROFILE = `${MODULE_ID}/UPDATE_HCP_PROFILE`;
export const UPDATE_HCP_PROFILE_SUCCESS = `${MODULE_ID}/UPDATE_HCP_PROFILE_SUCCESS`;
export const UPDATE_HCP_PROFILE_ERROR = `${MODULE_ID}/UPDATE_HCP_PROFILE_ERROR`;

export const FETCH_HCP_CUSTOM_IDENTIFIERS = `${MODULE_ID}/FETCH_HCP_CUSTOM_IDENTIFIERS`;
export const FETCH_HCP_CUSTOM_IDENTIFIERS_SUCCESS = `${MODULE_ID}/FETCH_HCP_CUSTOM_IDENTIFIERS_SUCCESS`;
export const FETCH_HCP_CUSTOM_IDENTIFIERS_ERROR = `${MODULE_ID}/FETCH_HCP_CUSTOM_IDENTIFIERS_ERROR`;

export const FETCH_MEMBERSHIPS = `${MODULE_ID}/FETCH_MEMBERSHIPS`;
export const FETCH_MEMBERSHIPS_SUCCESS = `${MODULE_ID}/FETCH_MEMBERSHIPS_SUCCESS`;
export const FETCH_MEMBERSHIPS_ERROR = `${MODULE_ID}/FETCH_MEMBERSHIPS_ERROR`;

export const CONNECT_TO_CLINIC = `${MODULE_ID}/CONNECT_TO_CLINIC`;
export const CONNECT_TO_CLINIC_SUCCESS = `${MODULE_ID}/CONNECT_TO_CLINIC_SUCCESS`;
export const CONNECT_TO_CLINIC_ERROR = `${MODULE_ID}/CONNECT_TO_CLINIC_ERROR`;

export const DISCONNECT_CLINIC = `${MODULE_ID}/DISCONNECT_CLINIC`;

export const GDPR_FETCH_ACCOUNT_DUMP = `${MODULE_ID}/GDPR_FETCH_ACCOUNT_DUMP`;
export const GDPR_FETCH_ACCOUNT_DUMP_SUCCESS = `${MODULE_ID}/GDPR_FETCH_ACCOUNT_DUMP_SUCCESS`;
export const GDPR_FETCH_ACCOUNT_DUMP_ERROR = `${MODULE_ID}/GDPR_FETCH_ACCOUNT_DUMP_ERROR`;

export const GDPR_SET_PHI_SET = `${MODULE_ID}/GDPR_SET_PHI_SET`;
export const GDPR_SET_CLOUD_DRIVE_DUMP = `${MODULE_ID}/GDPR_SET_CLOUD_DRIVE_DUMP`;
export const GDPR_CLEAR_DATA = `${MODULE_ID}/GDPR_CLEAR_DATA`;

export const GDPR_SAVE_TO_FILE = `${MODULE_ID}/GDPR_SAVE_TO_FILE`;
export const GDPR_SAVE_TO_FILE_SUCCESS = `${MODULE_ID}/GDPR_SAVE_TO_FILE_SUCCESS`;
export const GDPR_SAVE_TO_FILE_ERROR = `${MODULE_ID}/GDPR_SAVE_TO_FILE_ERROR`;

export const AUTH_CONTOUR_CLOUD = `${MODULE_ID}/AUTH_CONTOUR_CLOUD`;
export const AUTH_CONTOUR_CLOUD_SUCCESS = `${MODULE_ID}/AUTH_CONTOUR_CLOUD_SUCCESS`;
export const AUTH_CONTOUR_CLOUD_ERROR = `${MODULE_ID}/AUTH_CONTOUR_CLOUD_ERROR`;

export const SET_CHANGE_PASSWORD_RESET = `${MODULE_ID}/SET_CHANGE_PASSWORD_RESET`;

export const UPDATE_NOTIFICATIONS_SETTINGS = `${MODULE_ID}/UPDATE_NOTIFICATIONS_SETTINGS`;
export const UPDATE_NOTIFICATIONS_SETTINGS_SUCCESS = `${MODULE_ID}/UPDATE_NOTIFICATIONS_SETTINGS_SUCCESS`;
export const UPDATE_NOTIFICATIONS_SETTINGS_ERROR = `${MODULE_ID}/UPDATE_NOTIFICATIONS_SETTINGS_ERROR`;

export const GET_CC_ACCESS_TOKEN = `${MODULE_ID}/GET_CC_ACCESS_TOKEN`;
export const GET_CC_ACCESS_TOKEN_SUCCESS = `${MODULE_ID}/GET_CC_ACCESS_TOKEN_SUCCESS`;
export const GET_CC_ACCESS_TOKEN_ERROR = `${MODULE_ID}/GET_CC_ACCESS_TOKEN_ERROR`;

export const CHECK_MEMBERSHIPS = `${MODULE_ID}/CHECK_MEMBERSHIPS`;
export const CHECK_MEMBERSHIPS_SUCCESS = `${MODULE_ID}/CHECK_MEMBERSHIPS_SUCCESS`;
export const CHECK_MEMBERSHIPS_ERROR = `${MODULE_ID}/CHECK_MEMBERSHIPS_ERROR`;

export const VALIDATE_MEMBERSHIPS = `${MODULE_ID}/VALIDATE_MEMBERSHIPS`;
export const VALIDATE_MEMBERSHIPS_SUCCESS = `${MODULE_ID}/VALIDATE_MEMBERSHIPS_SUCCESS`;
export const VALIDATE_MEMBERSHIPS_ERROR = `${MODULE_ID}/VALIDATE_MEMBERSHIPS_ERROR`;
