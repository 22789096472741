import toInteger from 'lodash/toInteger';
import { WAIT_FOR_ACTION, ERROR_ACTION } from 'redux-wait-for-action';
import * as actionTypes from './actionTypes';


export const getMe = () => ({
  type             : actionTypes.GET_ME,
  [WAIT_FOR_ACTION]: actionTypes.GET_ME_SUCCESS,
  [ERROR_ACTION]   : actionTypes.GET_ME_ERROR,
});

export const getMeSuccess = ({
  isAuthenticated,
  account,
  hcpProfile,
  patientProfile,
  cgProfile,
  metricsUnits,
} = {}) => ({
  type   : actionTypes.GET_ME_SUCCESS,
  payload: {
    isAuthenticated,
    account,
    hcpProfile,
    patientProfile,
    cgProfile,
    metricsUnits,
  },
});

export const getMeError = (error) => ({
  type: actionTypes.GET_ME_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const authCallback = (code) => ({
  type   : actionTypes.AUTH_CALLBACK,
  payload: {
    code,
  },
});

//----------------------------------------------------------------------------------------------------------------------


export const fetchToken = () => ({
  type: actionTypes.FETCH_TOKEN,
});

//----------------------------------------------------------------------------------------------------------------------

export const changeAidPassword = () => ({
  type: actionTypes.CHANGE_AID_PASSWORD,
});

export const editAidAccount = () => ({
  type: actionTypes.EDIT_AID_ACCOUNT,
});

export const addAidOrganizationMembership = () => ({
  type: actionTypes.ADD_AID_ORGANIZATION_MEMBERSHIP,
});

export const manageAidOrganizationMemberships = () => ({
  type: actionTypes.MANAGE_AID_ORGANIZATION_MEMBERSHIPS,
});

//----------------------------------------------------------------------------------------------------------------------

export const validateAccount = () => ({
  type: actionTypes.VALIDATE_ACCOUNT,
});

export const checkPermissions = (rules = []) => ({
  type             : actionTypes.CHECK_PERMISSIONS,
  [WAIT_FOR_ACTION]: actionTypes.CHECK_PERMISSIONS_END,
  [ERROR_ACTION]   : actionTypes.CHECK_PERMISSIONS_END,
  payload          : {
    rules,
  },
});

//----------------------------------------------------------------------------------------------------------------------

export const setActiveProfileType = (activeProfileType) => ({
  type   : actionTypes.SET_ACTIVE_PROFILE_TYPE,
  payload: {
    activeProfileType,
  },
});

export const switchProfile = () => ({
  type: actionTypes.SWITCH_PROFILE,
});

//----------------------------------------------------------------------------------------------------------------------

export const setActiveClinicMembershipId = (activeClinicMembershipId, activeOrganizationUID) => ({
  type   : actionTypes.SET_ACTIVE_CLINIC_MEMBERSHIP_ID,
  payload: {
    activeClinicMembershipId: activeClinicMembershipId && toInteger(activeClinicMembershipId),
    activeOrganizationUID,
  },
});

export const setActiveClinicMembershipCleared = () => ({
  type: actionTypes.SET_ACTIVE_CLINIC_MEMBERSHIP_CLEARED,
});

//----------------------------------------------------------------------------------------------------------------------

export const fetchPatientDataFromInvitation = () => ({
  type: actionTypes.FETCH_PATIENT_DATA_FROM_INVITATION,
});

export const fetchPatientDataFromInvitationSuccess = (patientDataFromInvitation) => ({
  type   : actionTypes.FETCH_PATIENT_DATA_FROM_INVITATION_SUCCESS,
  payload: {
    patientDataFromInvitation,
  },
});

export const fetchPatientDataFromInvitationError = (error) => ({
  type: actionTypes.FETCH_PATIENT_DATA_FROM_INVITATION_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const fetchPatientHealthDataFromInvitation = (invitationCode, email) => ({
  type   : actionTypes.FETCH_PATIENT_HEALTH_DATA_FROM_INVITATION,
  payload: {
    invitationCode,
    email,
  },
});

export const fetchPatientHealthDataFromInvitationSuccess = (patientHealthDataFromInvitation) => ({
  type   : actionTypes.FETCH_PATIENT_HEALTH_DATA_FROM_INVITATION_SUCCESS,
  payload: {
    patientHealthDataFromInvitation,
  },
});

export const fetchPatientHealthDataFromInvitationError = (error) => ({
  type: actionTypes.FETCH_PATIENT_HEALTH_DATA_FROM_INVITATION_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const signIn = () => ({
  type: actionTypes.SIGN_IN,
});

export const signInSuccess = () => ({
  type: actionTypes.SIGN_IN_SUCCESS,
});

export const signInError = (error) => ({
  type: actionTypes.SIGN_IN_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const authContourCloud = (code, referenceId) => ({
  type   : actionTypes.AUTH_CONTOUR_CLOUD,
  payload: {
    code,
    referenceId,
  },
});

export const authContourCloudSuccess = () => ({
  type   : actionTypes.AUTH_CONTOUR_CLOUD_SUCCESS,
  payload: {},
});

export const authContourCloudError = (error) => ({
  type: actionTypes.AUTH_CONTOUR_CLOUD_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------


export const signOut = ({ preserveStorage } = {}) => ({
  type   : actionTypes.SIGN_OUT,
  payload: {
    preserveStorage,
  },
});

export const signOutSuccess = () => ({
  type: actionTypes.SIGN_OUT_SUCCESS,
});

export const signOutError = (error) => ({
  type: actionTypes.SIGN_OUT_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const setElevatedPermissionsMode = (mode) => ({
  type   : actionTypes.SET_ELEVATED_PERMISSIONS_MODE,
  payload: mode,
});

//----------------------------------------------------------------------------------------------------------------------

export const elevatePermissions = ({ password } = {}) => ({
  type   : actionTypes.ELEVATE_PERMISSIONS,
  payload: {
    password,
  },
});

export const elevatePermissionsSuccess = () => ({
  type: actionTypes.ELEVATE_PERMISSIONS_SUCCESS,
});

export const elevatePermissionsError = (error) => ({
  type: actionTypes.ELEVATE_PERMISSIONS_ERROR,
  error,
});

export const elevatePermissionsTimeout = () => ({
  type: actionTypes.ELEVATE_PERMISSIONS_TIMEOUT,
});

//----------------------------------------------------------------------------------------------------------------------

export const generateKeysPem = (passphrase = null) => ({
  type   : actionTypes.GENERATE_KEYS_PEM,
  payload: {
    passphrase,
  },
});

export const generateKeysPemSuccess = (prvKeyPem, pubKeyPem, passphrase, encryptedPassphrase) => ({
  type   : actionTypes.GENERATE_KEYS_PEM_SUCCESS,
  payload: {
    prvKeyPem,
    pubKeyPem,
    passphrase,
    encryptedPassphrase,
  },
});

export const generateKeysPemError = (error) => ({
  type: actionTypes.GENERATE_KEYS_PEM_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const setKeys = (prvKeyPem, pubKeyPem, encryptedPassphrase) => ({
  type   : actionTypes.SET_KEYS,
  payload: {
    prvKeyPem,
    pubKeyPem,
    encryptedPassphrase,
  },
});

//----------------------------------------------------------------------------------------------------------------------

export const getPassphrase = () => ({
  type: actionTypes.GET_PASSPHRASE,
});

export const getPassphraseSuccess = (passphrase) => ({
  type   : actionTypes.GET_PASSPHRASE_SUCCESS,
  payload: {
    passphrase,
  },
});

export const getPassphraseError = (error) => ({
  type: actionTypes.GET_PASSPHRASE_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const updateAccountSettings = (settings) => ({
  type   : actionTypes.UPDATE_ACCOUNT_SETTINGS,
  payload: {
    settings,
  },
});

export const updateAccountSettingsSuccess = (settings) => ({
  type   : actionTypes.UPDATE_ACCOUNT_SETTINGS_SUCCESS,
  payload: {
    settings,
  },
});

export const updateAccountSettingsError = (error) => ({
  type: actionTypes.UPDATE_ACCOUNT_SETTINGS_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const deleteAccount = () => ({
  type: actionTypes.DELETE_ACCOUNT,
});

export const deleteAccountSuccess = () => ({
  type: actionTypes.DELETE_ACCOUNT_SUCCESS,
});

export const deleteAccountError = (error) => ({
  type: actionTypes.DELETE_ACCOUNT_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const updatePatientProfile = ({
  storageProvider,
  storageAccount,
  encryptedExchangeToken,
  diabetesType,
  treatmentType,
  payer,
} = {}) => ({
  type   : actionTypes.UPDATE_PATIENT_PROFILE,
  payload: {
    storageProvider,
    storageAccount,
    encryptedExchangeToken,
    diabetesType,
    treatmentType,
    payer,
  },
});

export const updatePatientProfileSuccess = (patientProfile) => ({
  type   : actionTypes.UPDATE_PATIENT_PROFILE_SUCCESS,
  payload: {
    patientProfile,
  },
});

export const updatePatientProfileError = (error) => ({
  type: actionTypes.UPDATE_PATIENT_PROFILE_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const reauthorizePatientProfileCloudDrive = (authorizationCode) => ({
  type   : actionTypes.REAUTHORIZE_PATIENT_PROFILE_CLOUD_DRIVE,
  payload: {
    authorizationCode,
  },
});

export const reauthorizePatientProfileCloudDriveSuccess = (patientProfile) => ({
  type   : actionTypes.REAUTHORIZE_PATIENT_PROFILE_CLOUD_DRIVE_SUCCESS,
  payload: {
    patientProfile,
  },
});

export const reauthorizePatientProfileCloudDriveError = (error) => ({
  type: actionTypes.REAUTHORIZE_PATIENT_PROFILE_CLOUD_DRIVE_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const fetchCountrySettings = () => ({
  type: actionTypes.FETCH_COUNTRY_SETTINGS,
});

export const fetchCountrySettingsSuccess = (countrySettings = null) => ({
  type   : actionTypes.FETCH_COUNTRY_SETTINGS_SUCCESS,
  payload: {
    countrySettings,
  },
});

export const fetchCountrySettingsError = (error) => ({
  type: actionTypes.FETCH_COUNTRY_SETTINGS_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const fetchPayers = () => ({
  type: actionTypes.FETCH_PAYERS,
});

export const fetchPayersSuccess = (payers = []) => ({
  type   : actionTypes.FETCH_PAYERS_SUCCESS,
  payload: {
    payers,
  },
});

export const fetchPayersError = (error) => ({
  type: actionTypes.FETCH_PAYERS_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const fetchPatientProfileSupportedDevices = () => ({
  type: actionTypes.FETCH_PATIENT_PROFILE_SUPPORTED_DEVICES,
});

export const fetchPatientProfileSupportedDevicesSuccess = (supportedDevices) => ({
  type   : actionTypes.FETCH_PATIENT_PROFILE_SUPPORTED_DEVICES_SUCCESS,
  payload: {
    supportedDevices,
  },
});

export const fetchPatientProfileSupportedDevicesError = (error) => ({
  type: actionTypes.FETCH_PATIENT_PROFILE_SUPPORTED_DEVICES_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const connectToPatientStorage = (patientProfile) => ({
  type   : actionTypes.CONNECT_TO_PATIENT_STORAGE,
  payload: {
    patientProfile,
  },
});

export const connectToPatientStorageSuccess = (patientProfile) => ({
  type   : actionTypes.CONNECT_TO_PATIENT_STORAGE_SUCCESS,
  payload: {
    patientProfile,
  },
});

export const connectToPatientStorageError = (error) => ({
  type: actionTypes.CONNECT_TO_PATIENT_STORAGE_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

/**
 * @deprecated since version 2.0
 */
export const updateCgProfile = ({ avatar } = {}) => ({
  type   : actionTypes.UPDATE_CG_PROFILE,
  payload: {
    avatar,
  },
});

export const updateCgProfileSuccess = (cgProfile) => ({
  type   : actionTypes.UPDATE_CG_PROFILE_SUCCESS,
  payload: {
    cgProfile,
  },
});

export const updateCgProfileError = (error) => ({
  type: actionTypes.UPDATE_CG_PROFILE_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

/**
 * @deprecated since version 2.0
 */
export const updateHcpProfile = ({ avatar, customHcpIdentifiersValues } = {}) => ({
  type   : actionTypes.UPDATE_HCP_PROFILE,
  payload: {
    avatar,
    customHcpIdentifiersValues,
  },
});

export const updateHcpProfileSuccess = (hcpProfile) => ({
  type   : actionTypes.UPDATE_HCP_PROFILE_SUCCESS,
  payload: {
    hcpProfile,
  },
});

export const updateHcpProfileError = (error) => ({
  type: actionTypes.UPDATE_HCP_PROFILE_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const fetchHcpCustomIdentifiers = (countryId) => ({
  type   : actionTypes.FETCH_HCP_CUSTOM_IDENTIFIERS,
  payload: {
    countryId,
  },
});

export const fetchHcpCustomIdentifiersSuccess = (hcpCustomIdentifiers) => ({
  type   : actionTypes.FETCH_HCP_CUSTOM_IDENTIFIERS_SUCCESS,
  payload: {
    hcpCustomIdentifiers,
  },
});

export const fetchHcpCustomIdentifiersError = (error) => ({
  type: actionTypes.FETCH_HCP_CUSTOM_IDENTIFIERS_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const fetchMemberships = () => ({
  type: actionTypes.FETCH_MEMBERSHIPS,
});

export const fetchMembershipsSuccess = (clinicHcpMemberships) => ({
  type   : actionTypes.FETCH_MEMBERSHIPS_SUCCESS,
  payload: {
    clinicHcpMemberships,
  },
});

export const fetchMembershipsError = (error) => ({
  type: actionTypes.FETCH_MEMBERSHIPS_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const checkMemberships = () => ({
  type: actionTypes.CHECK_MEMBERSHIPS,
});

export const checkMembershipsSuccess = (organizationMembershipsWithoutClinics) => ({
  type   : actionTypes.CHECK_MEMBERSHIPS_SUCCESS,
  payload: {
    organizationMembershipsWithoutClinics,
  },
});

export const checkMembershipsError = (error) => ({
  type: actionTypes.CHECK_MEMBERSHIPS_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const validateMemberships = () => ({
  type: actionTypes.VALIDATE_MEMBERSHIPS,
});

export const validateMembershipsSuccess = () => ({
  type: actionTypes.VALIDATE_MEMBERSHIPS_SUCCESS,
});

export const validateMembershipsError = () => ({
  type: actionTypes.VALIDATE_MEMBERSHIPS_ERROR,
});

//----------------------------------------------------------------------------------------------------------------------

export const connectToClinic = (clinicMembership) => ({
  type   : actionTypes.CONNECT_TO_CLINIC,
  payload: {
    clinicMembership,
  },
});

export const connectToClinicSuccess = ({
  clinicHcpMembershipId,
  accessToken,
  passphrase,
  profilesReferenceKey,
  supportedDevices,
} = {}) => ({
  type   : actionTypes.CONNECT_TO_CLINIC_SUCCESS,
  payload: {
    clinicHcpMembershipId,
    accessToken,
    passphrase,
    profilesReferenceKey,
    supportedDevices,
  },
});

export const connectToClinicError = (error) => ({
  type: actionTypes.CONNECT_TO_CLINIC_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const disconnectClinic = () => ({
  type: actionTypes.DISCONNECT_CLINIC,
});

//----------------------------------------------------------------------------------------------------------------------

export const gdprFetchAccountDump = () => ({
  type: actionTypes.GDPR_FETCH_ACCOUNT_DUMP,
});

export const gdprFetchAccountDumpSuccess = (account) => ({
  type   : actionTypes.GDPR_FETCH_ACCOUNT_DUMP_SUCCESS,
  payload: {
    account,
  },
});

export const gdprFetchAccountDumpError = (error) => ({
  type: actionTypes.GDPR_FETCH_ACCOUNT_DUMP_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const gdprSetPhiSet = (phiSet) => ({
  type   : actionTypes.GDPR_SET_PHI_SET,
  payload: {
    phiSet,
  },
});

//----------------------------------------------------------------------------------------------------------------------

export const gdprClearData = () => ({
  type: actionTypes.GDPR_CLEAR_DATA,
});

//----------------------------------------------------------------------------------------------------------------------

export const gdprSetCloudDriveDump = (dump) => ({
  type   : actionTypes.GDPR_SET_CLOUD_DRIVE_DUMP,
  payload: {
    dump,
  },
});

//----------------------------------------------------------------------------------------------------------------------

export const gdprSaveToFile = () => ({
  type: actionTypes.GDPR_SAVE_TO_FILE,
});

export const gdprSaveToFileSuccess = () => ({
  type: actionTypes.GDPR_SAVE_TO_FILE_SUCCESS,
});

export const gdprSaveToFileError = (error) => ({
  type: actionTypes.GDPR_SAVE_TO_FILE_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const setChangePasswordReset = (changePasswordReset) => ({
  type   : actionTypes.SET_CHANGE_PASSWORD_RESET,
  payload: {
    changePasswordReset,
  },
});

//----------------------------------------------------------------------------------------------------------------------

export const updateNotificationsSettings = (notificationsSettings) => ({
  type   : actionTypes.UPDATE_NOTIFICATIONS_SETTINGS,
  payload: {
    notificationsSettings,
  },
});

export const updateNotificationsSettingsSuccess = (notificationsSettings) => ({
  type   : actionTypes.UPDATE_NOTIFICATIONS_SETTINGS_SUCCESS,
  payload: {
    notificationsSettings,
  },
});

export const updateNotificationsSettingsError = (error) => ({
  type: actionTypes.UPDATE_NOTIFICATIONS_SETTINGS_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const getCCAccessToken = ({ account, passphrase }) => ({
  type   : actionTypes.GET_CC_ACCESS_TOKEN,
  payload: {
    account,
    passphrase,
  },
});

export const getCCAccessTokenSuccess = (accessToken) => ({
  type   : actionTypes.GET_CC_ACCESS_TOKEN_SUCCESS,
  payload: {
    accessToken,
  },
});

export const getCCAccessTokenError = (error) => ({
  type: actionTypes.GET_CC_ACCESS_TOKEN_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------
