import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import { compare } from 'compare-versions';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import memoize from 'lodash/memoize';
import { AppContext } from 'context';
import BackButton from 'components/BackButton';
import Modal from 'components/Modal';
import App from 'modules/App';
import Account from 'modules/Account';
import Information from 'modules/Information';
import CloudDrive from 'modules/CloudDrive';
import Hcp from 'modules/Hcp';
import Patient from 'modules/Patient';
import countrySettingsShape from 'shapes/countrySettingsShape';
import { getStandards } from 'helpers/settings';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import * as shapes from '../../shapes';
import messages from '../../messages';
import styles from './DownloadDataModal.pcss';

import Initializing from './Initializing';
import Consents from './Consents';
import DownloaderOutdated from './DownloaderOutdated';

import InstallBlueCableDrivers from './InstallBlueCableDrivers';
import InstallingBlueCableDrivers from './InstallingBlueCableDrivers';
import InstalledBlueCableDrivers from './InstalledBlueCableDrivers';

import ChooseConnection from './ChooseConnection';

import BluetoothPairingInstruction from './BluetoothPairingInstruction';
import BluetoothPin from './BluetoothPin';

import ConnectBlueCable from './ConnectBlueCable';
import ConnectBluetooth from './ConnectBluetooth';
import ConnectUsb from './ConnectUsb';
import ConnectUsbCCable from './ConnectUsbCCable';
import ConnectUsbCable from './ConnectUsbCable';

import SccWaiting from './SccWaiting';

import Downloading from './Downloading';
import Timeout from './Timeout';
import Results from './Results';

import AddPatient from './AddPatient';
import ChoosePatient from './ChoosePatient';
import WarningsBeforeDownloadData from './WarningsBeforeDownloadData';


class DownloadDataModal extends React.PureComponent {

  static contextType = AppContext;

  static propTypes = {
    // Explicit props
    activeProfileType     : Account.shapes.profileType,
    activeVisit           : PropTypes.object,
    activeClinicMembership: Account.shapes.clinicMembership,
    countrySettings       : countrySettingsShape,
    country               : PropTypes.object,
    supportedDevices      : PropTypes.arrayOf(App.shapes.device),
    // Implicit props
    downloader            : shapes.downloader,
    status                : shapes.status,
    connectionId          : PropTypes.string,
    connectionStatus      : shapes.connectionStatus,
    connectionError       : PropTypes.string,
    consentsTemplate      : PropTypes.arrayOf(Information.shapes.consentTemplate),
    information           : PropTypes.shape({
      legalConsentApprovals: PropTypes.arrayOf(Information.shapes.consentApproval),
    }),
    devices     : PropTypes.arrayOf(App.shapes.device),
    caseTypes   : PropTypes.arrayOf(App.shapes.caseType),
    phiSet      : PropTypes.object, // @TODO: shape
    openModalId : PropTypes.string,
    isDisabled  : PropTypes.bool,
    // Implicit actions
    onCloseModal: PropTypes.func,
  };


  constructor(props) {
    super(props);
    const { devices, supportedDevices } = props;

    this.components = {
      Initializing,
      Consents,
      DownloaderOutdated,

      InstallBlueCableDrivers,
      InstallingBlueCableDrivers,
      InstalledBlueCableDrivers,

      ChooseConnection,
      BluetoothPairingInstruction,
      BluetoothPin,
      ConnectBlueCable,
      ConnectBluetooth,
      ConnectUsb,
      ConnectUsbCable,
      ConnectUsbCCable,

      SccWaiting,

      Downloading,
      Timeout,
      Results,

      AddPatient,
      ChoosePatient,
      WarningsBeforeDownloadData,
    };

    this.defaultComponent = 'Initializing';
    this.connectorConsentTemplate = this.getNoApprovalConsents();
    this.devicesByChannel = this.getDevicesByChannel(devices, supportedDevices);
    this.getStandards = memoize(getStandards);

    this.state = {
      component      : this.connectorConsentTemplate.length ? 'Consents' : this.defaultComponent,
      selectedChannel: null,
      tryAgainPath   : false,
      warnings       : [],
    };

  }


  componentDidUpdate(prevProps) {
    const { devices, supportedDevices, status, connectionStatus, connectionError, information } = this.props;
    if (prevProps.supportedDevices !== supportedDevices) {
      this.devicesByChannel = this.getDevicesByChannel(devices, supportedDevices);
      return;
    }

    if (!this.isOpen) {
      return;
    }

    if (prevProps.information !== information) {
      this.connectorConsentTemplate = this.getNoApprovalConsents();
    }

    if (prevProps.status !== status) {
      if (status !== constants.DOWNLOADER_STATUSES.NO_CONNECTION && this.isDownloaderOutdated) {
        this.onSetComponent('DownloaderOutdated');
        return;
      }
    }

    if (prevProps.connectionStatus !== connectionStatus) {
      if (connectionStatus === constants.CONNECTION_STATUSES.ERROR) {
        if (connectionError === 'NoLicense') {
          this.onSetComponent('NoLicense');
          return;
        }
        this.onSetComponent('ConnectionFailed');
      }
    }
  }


  async onClose() {
    const { connectionId, connectionStatus } = this.props;
    await this.props.onCloseModal(connectionId, connectionStatus);
    const component = this.connectorConsentTemplate.length ? 'Consents' : this.defaultComponent;
    this.setState({ component });
  }


  onSetComponent(componentName) {
    this.setState({ component: componentName });
  }


  onSetChannel(componentName) {
    this.setState({ selectedChannel: componentName });
  }


  onSetAgainPath(tryAgain) {
    this.setState({ tryAgainPath: tryAgain });
  }


  onAddWarning(warning) {
    this.setState((prevState) => ({ warnings: [...prevState.warnings, warning] }));
  }


  onClearWarnings() {
    this.setState({ warnings: [] });
  }


  getNoApprovalConsents() {
    if (this.connectorType === constants.CONNECTOR_TYPES.MDA) {
      return [];
    }
    const { consentsTemplate } = this.props;
    const { legalConsentApprovals } = this.props.information;
    const noApprovalConsentTemplate = [];
    const connectorConsentTemplate = consentsTemplate
      && consentsTemplate.filter((ct) => ct.type === 'ThirdPartyConnector');
    connectorConsentTemplate.forEach((cct) => {
      const cctApproval = legalConsentApprovals.find((lca) => lca.legalConsentId === cct.legalConsentId);
      if (!cctApproval || !cctApproval.decision) {
        noApprovalConsentTemplate.push(cct);
      }
    });
    return noApprovalConsentTemplate;
  }


  get connectorType() {
    return get(this.props.activeClinicMembership, 'clinic.settings.connectors.default', 'MDA'); // 'MDA', 'SCC'
  }


  get standards() {
    const { activeClinicMembership, countrySettings, phiSet } = this.props;
    const clinicSettings = get(activeClinicMembership, 'clinic.settings') || countrySettings;
    return this.getStandards(phiSet, countrySettings, clinicSettings);
  }


  get isDownloaderOutdated() {
    const clientVersion = get(this.props, 'downloader.mdaVersion');
    if (!clientVersion) {
      return false;
    }
    const minVersion = get(this.context, 'downloader.minVersion');
    return compare(clientVersion, minVersion, '<');
  }


  get isOpen() {
    return this.props.openModalId === constants.DOWNLOAD_DATA_MODAL;
  }


  get isResultsComponent() {
    return this.state.component === 'Results';
  }


  get isAddPatientComponent() {
    return this.state.component === 'AddPatient';
  }


  get isChoosePatientComponent() {
    return this.state.component === 'ChoosePatient';
  }


  get isConsentsComponent() {
    return this.state.component === 'Consents';
  }


  get isWarningsBeforeDownloadData() {
    return this.state.component === 'WarningsBeforeDownloadData';
  }


  get isConnectionFailedComponent() {
    return this.state.component === 'ConnectionFailed';
  }


  get isNoLicenseComponent() {
    return this.state.component === 'NoLicense';
  }


  get isDownloaderOutdatedComponent() {
    return this.state.component === 'DownloaderOutdated';
  }


  get headerMessage() {
    const { warnings } = this.state;

    if (this.isResultsComponent) {
      return messages.headers.patientResults;
    }

    if (this.isAddPatientComponent) {
      return messages.addPatient.header;
    }

    if (this.isChoosePatientComponent) {
      return messages.choosePatient.header;
    }

    if (this.isConsentsComponent) {
      return messages.headers.warningsBeforeDownloadData;
    }

    if (this.isWarningsBeforeDownloadData) {
      const warning = warnings[0];
      if (warnings.length === 1 && messages.headers[`warning${warning}`]) {
        return messages.headers[`warning${warning}`];
      }

      return messages.headers.warningsBeforeDownloadData;
    }

    if (this.isConnectionFailedComponent) {
      return messages.headers.connectedFailed;
    }

    if (this.isNoLicenseComponent) {
      return messages.headers.noLicense;
    }

    if (this.isDownloaderOutdatedComponent) {
      return messages.headers.downloaderOutdated;
    }

    return messages.headers.downloadDataFromMeter;
  }


  get styleModifier() {
    const { component } = this.state;
    if (component === 'ChooseConnection') {
      return 'xl';
    }
    if (component === 'ConnectBluetooth') {
      return 'xl';
    }
    if (component === 'BluetoothPairingInstruction') {
      return 'lg';
    }
    if (component === 'ChoosePatient' || component === 'AddPatient') {
      return 'md';
    }
    return null;
  }


  getDevicesByChannel(devices, supportedDevices) {
    const devicesSet = isEmpty(supportedDevices) ? devices : supportedDevices;
    const devicesByChannel = {};
    forEach(devicesSet, (device) => forEach(device.channels, (channel) => {
      if (!devicesByChannel[channel]) {
        devicesByChannel[channel] = [];
      }
      devicesByChannel[channel].push(device);
    }));
    return devicesByChannel;
  }


  renderBack() {
    if (!(this.isChoosePatientComponent || this.isAddPatientComponent)) {
      return null;
    }

    let componentName;
    if (this.isChoosePatientComponent) {
      componentName = 'Results';
    } else if (this.isAddPatientComponent) {
      componentName = 'ChoosePatient';
    }

    return (
      <BackButton
        to=""
        onClick={(evt) => {
          evt.preventDefault();
          this.onSetComponent(componentName);
        }}
      />
    );
  }


  renderContent() {
    if (!this.isOpen) {
      return null;
    }
    const { component } = this.state;
    let Component;
    let subComponent = null;
    if (includes(constants.MDA_INIT_SUBCOMPONENTS, component)) {
      Component = this.components.Initializing;
      subComponent = component;
    } else {
      Component = get(this.components, component, null);
    }
    return (
      <Component
        subComponent={subComponent}
        connectorType={this.connectorType}
        connectorConsentTemplate={this.connectorConsentTemplate}
        phiSet={this.props.phiSet}
        activeVisit={this.props.activeVisit}
        downloader={this.props.downloader}
        devicesByChannel={this.devicesByChannel}
        caseTypes={this.props.caseTypes}
        selectedChannel={this.state.selectedChannel}
        warnings={this.state.warnings}
        standards={this.standards}
        activeProfileType={this.props.activeProfileType}
        activeClinicMembership={this.props.activeClinicMembership}
        countrySettings={this.props.countrySettings}
        country={this.props.country}
        isOpen={this.isOpen}
        tryAgainPath={this.state.tryAgainPath}
        onSetComponent={(componentName) => this.onSetComponent(componentName)}
        onCancel={() => this.onClose()}
        onSetAgainPath={(tryAgain) => this.onSetAgainPath(tryAgain)}
        onSetChannel={(componentName) => this.onSetChannel(componentName)}
        onAddWarning={(warning) => this.onAddWarning(warning)}
        onClearWarnings={() => this.onClearWarnings()}
      />
    );
  }


  render() {
    return (
      <Modal
        modalId={constants.DOWNLOAD_DATA_MODAL}
        openModalId={this.props.openModalId}
        styleModifier={this.styleModifier}
        isDisabled={this.props.isDisabled}
        onClose={() => this.onClose()}
      >
        { this.renderBack() }
        <h1 className="modal__header"><FormattedMessage {...this.headerMessage} /></h1>
        <App.components.AlertsBus className="mb-4" />
        { this.renderContent() }
      </Modal>
    );
  }

}


const mapStateToProps = (state, props) => {
  const { activeProfileType } = props;
  const getPhiSet = activeProfileType === Account.constants.PROFILE_TYPES.HCP
    ? Hcp.selectors.phiSet
    : Patient.selectors.phiSet;
  const countryId = Account.selectors.countryId(state);
  return {
    downloader      : selectors.downloader(state),
    status          : selectors.status(state),
    connectionId    : selectors.connectionId(state),
    connectionStatus: selectors.connectionStatus(state),
    connectionError : selectors.connectionError(state),
    phiSet          : getPhiSet(state),
    information     : Information.selectors.information(state),
    consentsTemplate: Information.selectors.consentsTemplate(state),
    devices         : App.selectors.devices(state),
    caseTypes       : App.selectors.caseTypes(state),
    openModalId     : App.selectors.modal(state),
    isDisabled      : CloudDrive.selectors.isStoreReadingsInProgress(state),
    country         : App.selectors.countryById(countryId)(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onCloseModal: (connectionId, connectionStatus) => Promise.all([
    dispatch(App.actions.closeModal()),
    dispatch(actions.stopCheckingConnection(connectionId, connectionStatus)),
    dispatch(actions.stopListeningBluetooth()),
    dispatch(actions.stopInstallBlueCableDriver()),
  ]),
});


const ConnectedDownloadDataModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DownloadDataModal);


export default withStyles(styles)(ConnectedDownloadDataModal);
